import { Locale } from './types';

export const LANGUAGES_FALLBACK: Locale = 'en';
export const LANGUAGES_AVAILABLE: Locale[] = [LANGUAGES_FALLBACK, 'de'];
export const LANGUAGES_DEFAULT_NS = 'default';
export const LANGUAGES_COOKIE_NAME = 'i18next';

export function getOptions(lng = LANGUAGES_FALLBACK, ns = LANGUAGES_DEFAULT_NS) {
  return {
    // debug: true,
    supportedLngs: LANGUAGES_AVAILABLE,
    // preload: languages,
    fallbackLng: LANGUAGES_FALLBACK,
    lng,
    fallbackNS: LANGUAGES_DEFAULT_NS,
    defaultNS: LANGUAGES_DEFAULT_NS,
    ns
    // backend: {
    //   projectId: '01b2e5e8-6243-47d1-b36f-963dbb8bcae3'
    // }
  };
}
