import { getPatternStyles, patternFns } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const desktopContainerConfig = {
transform(props) {
  return {
    display: { base: "none", xl: "block" },
    ...props
  };
}}

export const getDesktopContainerStyle = (styles = {}) => {
  const _styles = getPatternStyles(desktopContainerConfig, styles)
  return desktopContainerConfig.transform(_styles, patternFns)
}

export const desktopContainer = (styles) => css(getDesktopContainerStyle(styles))
desktopContainer.raw = getDesktopContainerStyle