import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const cmsContentSliceFn = /* @__PURE__ */ createRecipe('cms-content-slice', {}, [])

const cmsContentSliceVariantMap = {
  "spaceAfter": [
    "none",
    "sm",
    "md",
    "lg",
    "xl"
  ],
  "spaceBefore": [
    "none",
    "sm",
    "md",
    "lg",
    "xl"
  ]
}

const cmsContentSliceVariantKeys = Object.keys(cmsContentSliceVariantMap)

export const cmsContentSlice = /* @__PURE__ */ Object.assign(memo(cmsContentSliceFn.recipeFn), {
  __recipe__: true,
  __name__: 'cmsContentSlice',
  __getCompoundVariantCss__: cmsContentSliceFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: cmsContentSliceVariantKeys,
  variantMap: cmsContentSliceVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, cmsContentSliceVariantKeys)
  },
  getVariantProps: cmsContentSliceFn.getVariantProps,
})