import { getPatternStyles, patternFns } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const srOnlyConfig = {
transform(props) {
  return {
    srOnly: true,
    ...props
  };
}}

export const getSrOnlyStyle = (styles = {}) => {
  const _styles = getPatternStyles(srOnlyConfig, styles)
  return srOnlyConfig.transform(_styles, patternFns)
}

export const srOnly = (styles) => css(getSrOnlyStyle(styles))
srOnly.raw = getSrOnlyStyle