import { getPatternStyles, patternFns } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const mobileContainerConfig = {
transform(props) {
  return {
    display: { base: "block", xl: "none" },
    ...props
  };
}}

export const getMobileContainerStyle = (styles = {}) => {
  const _styles = getPatternStyles(mobileContainerConfig, styles)
  return mobileContainerConfig.transform(_styles, patternFns)
}

export const mobileContainer = (styles) => css(getMobileContainerStyle(styles))
mobileContainer.raw = getMobileContainerStyle