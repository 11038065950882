import { getPatternStyles, patternFns } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const spaceConfig = {
transform(props) {
  const { size, isHalf, ...rest } = props;
  let height = 0;
  if (!isHalf) {
    switch (size) {
      case "xl":
        height = "xl";
        break;
      case "lg":
        height = "lg";
        break;
      case "md":
        height = "md";
        break;
      case "sm":
        height = "sm";
        break;
      default:
        break;
    }
  } else {
    switch (size) {
      case "xl":
        height = "xl/2";
        break;
      case "lg":
        height = "lg/2";
        break;
      case "md":
        height = "md/2";
        break;
      case "sm":
        height = "sm/2";
        break;
      default:
        break;
    }
  }
  return {
    h: height,
    ...rest
  };
}}

export const getSpaceStyle = (styles = {}) => {
  const _styles = getPatternStyles(spaceConfig, styles)
  return spaceConfig.transform(_styles, patternFns)
}

export const space = (styles) => css(getSpaceStyle(styles))
space.raw = getSpaceStyle