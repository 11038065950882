import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const dropdownDefaultVariants = {}
const dropdownCompoundVariants = []

const dropdownSlotNames = [
  [
    "subTrigger",
    "dropdown__subTrigger"
  ],
  [
    "subTriggerIcon",
    "dropdown__subTriggerIcon"
  ],
  [
    "subContent",
    "dropdown__subContent"
  ],
  [
    "content",
    "dropdown__content"
  ],
  [
    "item",
    "dropdown__item"
  ],
  [
    "itemIcon",
    "dropdown__itemIcon"
  ],
  [
    "checkboxItem",
    "dropdown__checkboxItem"
  ],
  [
    "checkboxItemIcon",
    "dropdown__checkboxItemIcon"
  ],
  [
    "radioWrapper",
    "dropdown__radioWrapper"
  ],
  [
    "radioCircle",
    "dropdown__radioCircle"
  ],
  [
    "radioItem",
    "dropdown__radioItem"
  ],
  [
    "label",
    "dropdown__label"
  ],
  [
    "shortcut",
    "dropdown__shortcut"
  ],
  [
    "separator",
    "dropdown__separator"
  ]
]
const dropdownSlotFns = /* @__PURE__ */ dropdownSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, dropdownDefaultVariants, getSlotCompoundVariant(dropdownCompoundVariants, slotName))])

const dropdownFn = memo((props = {}) => {
  return Object.fromEntries(dropdownSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const dropdownVariantKeys = [
  "active",
  "disabled",
  "checked"
]
const getVariantProps = (variants) => ({ ...dropdownDefaultVariants, ...compact(variants) })

export const dropdown = /* @__PURE__ */ Object.assign(dropdownFn, {
  __recipe__: false,
  __name__: 'dropdown',
  raw: (props) => props,
  variantKeys: dropdownVariantKeys,
  variantMap: {
  "active": [
    "true"
  ],
  "disabled": [
    "true"
  ],
  "checked": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, dropdownVariantKeys)
  },
  getVariantProps
})