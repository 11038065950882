export const filterSitemapCms = (data: any) => {
  const newData = [];

  if (process.env.APP_ENV !== 'prod') {
    return data;
  }

  for (let i = 0; i < data.length; i++) {
    const element = data[i];
    const newItems = [];

    for (let j = 0; j < element.items.length; j++) {
      const item = element.items[j];

      if (item.title !== 'CMS') {
        newItems.push(item);
      }
    }

    element.items = newItems;
    newData.push(element);
  }

  return newData;
};
