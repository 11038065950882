import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const selectDefaultVariants = {}
const selectCompoundVariants = []

const selectSlotNames = [
  [
    "trigger",
    "select__trigger"
  ],
  [
    "content",
    "select__content"
  ],
  [
    "viewport",
    "select__viewport"
  ],
  [
    "label",
    "select__label"
  ],
  [
    "item",
    "select__item"
  ],
  [
    "itemSpan",
    "select__itemSpan"
  ],
  [
    "itemIcon",
    "select__itemIcon"
  ],
  [
    "separator",
    "select__separator"
  ],
  [
    "scrollUp",
    "select__scrollUp"
  ],
  [
    "scrollDown",
    "select__scrollDown"
  ],
  [
    "triggerIcon",
    "select__triggerIcon"
  ],
  [
    "primitiveIcon",
    "select__primitiveIcon"
  ]
]
const selectSlotFns = /* @__PURE__ */ selectSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, selectDefaultVariants, getSlotCompoundVariant(selectCompoundVariants, slotName))])

const selectFn = memo((props = {}) => {
  return Object.fromEntries(selectSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const selectVariantKeys = [
  "isPopover"
]
const getVariantProps = (variants) => ({ ...selectDefaultVariants, ...compact(variants) })

export const select = /* @__PURE__ */ Object.assign(selectFn, {
  __recipe__: false,
  __name__: 'select',
  raw: (props) => props,
  variantKeys: selectVariantKeys,
  variantMap: {
  "isPopover": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, selectVariantKeys)
  },
  getVariantProps
})