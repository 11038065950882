export const hexToRgb = (str: string, alpha?: number) => {
  if (/^#([0-9a-f]{3}|[0-9a-f]{6})$/gi.test(str)) {
    var hex = str.substr(1);
    hex = hex.length == 3 ? hex.replace(/(.)/g, '$1$1') : hex;
    var rgb = parseInt(hex, 16);

    return `rgba(${[(rgb >> 16) & 255, (rgb >> 8) & 255, rgb & 255].join(',')}${
      alpha ? `, ${alpha}` : ''
    })`;
  }

  return false;
};
