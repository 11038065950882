import { TFunction, createInstance, i18n } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next/initReactI18next';

import { LANGUAGES_FALLBACK, LANGUAGES_DEFAULT_NS, LANGUAGES_AVAILABLE } from './i18n.settings';
import type { Locale } from './types';

type I18nNamespace = 'default';

const getOptions = (lng = LANGUAGES_FALLBACK, ns = LANGUAGES_DEFAULT_NS) => {
  return {
    // debug: true,
    supportedLngs: LANGUAGES_AVAILABLE,
    // preload: languages,
    fallbackLng: LANGUAGES_FALLBACK,
    lng,
    fallbackNS: LANGUAGES_DEFAULT_NS,
    defaultNS: LANGUAGES_DEFAULT_NS,
    ns
    // backend: {
    //   projectId: '01b2e5e8-6243-47d1-b36f-963dbb8bcae3'
    // }
  };
};

const initI18next = async (lng: Locale, ns: I18nNamespace) => {
  // on server side we create a new instance for each render, because during compilation everything seems to be executed in parallel
  const i18nInstance = createInstance();
  await i18nInstance
    .use(initReactI18next)
    .use(
      resourcesToBackend(
        (language: string, namespace: string) => import(`./locales/${language}/${namespace}.json`)
      )
    )
    .init(getOptions(lng, ns));

  return i18nInstance;
};

export const getServerTranslation = async (
  lng: Locale,
  ns: I18nNamespace = 'default',
  options?: any
): Promise<{ t: TFunction<any, any>; i18n: i18n }> => {
  const i18nextInstance = await initI18next(lng, ns);

  return {
    t: i18nextInstance.getFixedT(lng, Array.isArray(ns) ? ns[0] : ns, options?.keyPrefix),
    i18n: i18nextInstance
  };
};
