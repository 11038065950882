import { getPatternStyles, patternFns } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const truncateConfig = {
transform(props) {
  return {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    ...props
  };
}}

export const getTruncateStyle = (styles = {}) => {
  const _styles = getPatternStyles(truncateConfig, styles)
  return truncateConfig.transform(_styles, patternFns)
}

export const truncate = (styles) => css(getTruncateStyle(styles))
truncate.raw = getTruncateStyle