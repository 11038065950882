import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const carouselDefaultVariants = {
  "orientation": "horizontal",
  "animation": "slide"
}
const carouselCompoundVariants = [
  {
    "orientation": "horizontal",
    "animation": "fade",
    "css": {
      "item": {
        "pl": 0
      }
    }
  },
  {
    "orientation": "vertical",
    "animation": "fade",
    "css": {
      "item": {
        "pt": 0
      }
    }
  }
]

const carouselSlotNames = [
  [
    "root",
    "carousel__root"
  ],
  [
    "content",
    "carousel__content"
  ],
  [
    "contentInner",
    "carousel__contentInner"
  ],
  [
    "item",
    "carousel__item"
  ],
  [
    "pagination",
    "carousel__pagination"
  ],
  [
    "paginationBtn",
    "carousel__paginationBtn"
  ],
  [
    "paginationDot",
    "carousel__paginationDot"
  ]
]
const carouselSlotFns = /* @__PURE__ */ carouselSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, carouselDefaultVariants, getSlotCompoundVariant(carouselCompoundVariants, slotName))])

const carouselFn = memo((props = {}) => {
  return Object.fromEntries(carouselSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const carouselVariantKeys = [
  "orientation",
  "animation"
]
const getVariantProps = (variants) => ({ ...carouselDefaultVariants, ...compact(variants) })

export const carousel = /* @__PURE__ */ Object.assign(carouselFn, {
  __recipe__: false,
  __name__: 'carousel',
  raw: (props) => props,
  variantKeys: carouselVariantKeys,
  variantMap: {
  "orientation": [
    "horizontal",
    "vertical"
  ],
  "animation": [
    "slide",
    "fade"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, carouselVariantKeys)
  },
  getVariantProps
})