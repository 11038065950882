'use client';

import type { CSSProperties, PropsWithChildren } from 'react';
import type React from 'react';
import { createElement } from 'react';

import { HtmlTagName } from '@lib/types';

export interface DynamicTagProps extends PropsWithChildren {
  tagName?: HtmlTagName;
  props?: any;
  className?: string;
  style?: CSSProperties;
}

export const DynamicTag: React.FC<DynamicTagProps> = ({ tagName, children, ...props }) =>
  createElement(tagName?.toLowerCase() || 'span', props, children);
