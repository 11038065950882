export const openInNewTab = (url?: string): void => {
  if (!url || !document) return;

  const link = Object.assign(document.createElement('a'), {
    target: '_blank',
    url
  });

  link.setAttribute('href', url);
  link.click();

  document.removeChild(link);
};
