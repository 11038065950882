import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const borderBoxFn = /* @__PURE__ */ createRecipe('border-box', {}, [])

const borderBoxVariantMap = {}

const borderBoxVariantKeys = Object.keys(borderBoxVariantMap)

export const borderBox = /* @__PURE__ */ Object.assign(memo(borderBoxFn.recipeFn), {
  __recipe__: true,
  __name__: 'borderBox',
  __getCompoundVariantCss__: borderBoxFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: borderBoxVariantKeys,
  variantMap: borderBoxVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, borderBoxVariantKeys)
  },
  getVariantProps: borderBoxFn.getVariantProps,
})