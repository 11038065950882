import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsLinkDefaultVariants = {}
const cmsLinkCompoundVariants = []

const cmsLinkSlotNames = [
  [
    "root",
    "cmsLink__root"
  ],
  [
    "navLink",
    "cmsLink__navLink"
  ],
  [
    "socialLink",
    "cmsLink__socialLink"
  ]
]
const cmsLinkSlotFns = /* @__PURE__ */ cmsLinkSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsLinkDefaultVariants, getSlotCompoundVariant(cmsLinkCompoundVariants, slotName))])

const cmsLinkFn = memo((props = {}) => {
  return Object.fromEntries(cmsLinkSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsLinkVariantKeys = []
const getVariantProps = (variants) => ({ ...cmsLinkDefaultVariants, ...compact(variants) })

export const cmsLink = /* @__PURE__ */ Object.assign(cmsLinkFn, {
  __recipe__: false,
  __name__: 'cmsLink',
  raw: (props) => props,
  variantKeys: cmsLinkVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, cmsLinkVariantKeys)
  },
  getVariantProps
})