import { isServer } from '@lib/utils';

import { LANGUAGES_FALLBACK } from './i18n.settings';
import { Locale } from './types';

const getLangs = (ibeUid: string): Locale[] => {
  if (!process.env.LANGUAGES) {
    return [LANGUAGES_FALLBACK];
  }

  try {
    const map: { [tenant: string]: Locale[] } = {};
    const configs = process.env.LANGUAGES.split(';');

    configs.forEach(c => {
      const [tenant, langs] = c.split(':');

      if (tenant && langs) {
        const lngs = langs.split(',');
        map[tenant] = lngs.length ? (lngs as Locale[]) : [LANGUAGES_FALLBACK];
      }
    });

    return map[ibeUid] || [LANGUAGES_FALLBACK];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);

    return [LANGUAGES_FALLBACK];
  }
};

export const getLanguages = (tenantUid: string): Locale[] => {
  if (isServer()) {
    /**
     * TODO FIX THIS LOOKUP ON CMS LANGUAGES THEN
     */
    return getLangs(tenantUid);
  }

  return window._env_?.languages || [LANGUAGES_FALLBACK];
};
