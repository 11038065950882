import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const filterListDefaultVariants = {}
const filterListCompoundVariants = []

const filterListSlotNames = [
  [
    "root",
    "filter-list__root"
  ],
  [
    "toggleGroup",
    "filter-list__toggleGroup"
  ],
  [
    "toggleItem",
    "filter-list__toggleItem"
  ]
]
const filterListSlotFns = /* @__PURE__ */ filterListSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, filterListDefaultVariants, getSlotCompoundVariant(filterListCompoundVariants, slotName))])

const filterListFn = memo((props = {}) => {
  return Object.fromEntries(filterListSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const filterListVariantKeys = []
const getVariantProps = (variants) => ({ ...filterListDefaultVariants, ...compact(variants) })

export const filterList = /* @__PURE__ */ Object.assign(filterListFn, {
  __recipe__: false,
  __name__: 'filterList',
  raw: (props) => props,
  variantKeys: filterListVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, filterListVariantKeys)
  },
  getVariantProps
})