import { getPatternStyles, patternFns } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const containerConfig = {
transform(props) {
  const { noPadding, fullWidth, ...rest } = props;
  const defaultMaxWidth = 1728;
  let maxW = `${defaultMaxWidth}px`;
  if (fullWidth) {
    maxW = "auto";
  }
  if (!fullWidth && noPadding) {
    maxW = `${defaultMaxWidth - 48}px`;
  }
  return {
    maxWidth: maxW,
    mx: "auto",
    w: "full",
    px: noPadding ? 0 : { base: 4, lg: 6 },
    position: "relative",
    ...rest
  };
}}

export const getContainerStyle = (styles = {}) => {
  const _styles = getPatternStyles(containerConfig, styles)
  return containerConfig.transform(_styles, patternFns)
}

export const container = (styles) => css(getContainerStyle(styles))
container.raw = getContainerStyle